import React, { Component } from "react";
import { Link } from 'gatsby';

import utility_data from "../utilities/browser_information.js";

export default class WorkWithUsButton extends Component {
	constructor(props){
		super(props);
		this.state = {
			show_button: false
		}
		this.scroll_handler = this.scroll_handler.bind(this);
	}
	componentDidMount(){
		window.addEventListener('scroll', this.scroll_handler);
	}
	componentWillUnmount(){
		window.removeEventListener('scroll', this.scroll_handler);
	}
	scroll_handler(){
		if(utility_data.scrollTop > (utility_data().browser_height + 100)){
			if(this.state.show_button === false){
				this.setState({
					show_button: true
				});
			}
		} else{
			if(this.state.show_button === true){
				this.setState({
					show_button: false
				});
			}
		}
	}
	render(){
		let show_button = this.state.show_button ? " show-button" : "";
		return <div className={"grid-m-hide grid-t-hide work-with-us-container" + show_button}>
			<Link to="/contact/new-business" title="Get in touch for a new business">
				<span className="button">Work with us</span>
			</Link>
		</div>;
	}
}