import React, { Component } from "react";
import utility_data from "../utilities/browser_information.js";

import detectObjectFit from '../utilities/detectObjectFit'
import { makeObjectFit } from '../polyfill/object-fit'

export default class Basic_Hero extends Component {
	constructor(props){
		super(props);
		this.state = {
			height: 700
		}
		this.update_height = this.update_height.bind(this);
	}
	update_height(){
			this.setState({height: utility_data().browser_height})
	}
	componentDidMount(){
		this.update_height();
		window.addEventListener("resize", this.update_height);

		if(!detectObjectFit()){
			makeObjectFit()
		}
	}
	componentWillUnmount(){
		window.removeEventListener("resize", this.update_height);
	}
	render(){
		let height = this.state.height;
		let outside_classes = this.props.className ? " " + this.props.className : "";
		if(!this.props.fullHeight){
			height = "auto";
		}
		return <div className={"basic-hero" + outside_classes} style={{height: height + "px"}}>
			<div className="content">
				<div className="grid">
					<div className="grid-m-6 grid-t-12 grid-d-12 content-contain">
						{this.props.children}
					</div>
				</div>
			</div>
		</div>;
	}
}
