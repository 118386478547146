import React, { Component } from "react";

import Beautiful from "../../assets/svg/beautiful.svg";
import { ArrowLink } from "../buttons/Arrow_Link.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";
import BrowserView from "../utilities/mobileViewCheck.js";
import LazyLoad from "../repeating_elements/Lazy_Load.js";

const fallback = "https://api.thecharlesnyc.com/wp-content/uploads/sites/5/2019/10/footer-fallback.jpg";

export default class GetInTouchFooter extends Component {
	constructor(props) {
		super(props)
		this.state = {
			visible: false
		}
	}
	setInView() {
		this.setState({
			visible: true
		})
	}
	render(){
		return(
			<div className={"get-in-touch-footer-container"}>
				<div className="grid">
					<div className="grid-m-6 grid-t-16 grid-d-16">
						<LazyLoad>
							<div className="get-in-touch-footer">
								<div className="module-content">
									<p className="styling-header-1" style={{marginBottom: "0.67em"}}>Want to create<br /> something <span className="beautiful-container"><span>beautiful?</span><Beautiful /></span></p>
									<ArrowLink title="New Business" color="white" url="/contact/new-business" /><span style={{display: 'inline-block', width: '60px'}}></span>
									<ArrowLink title="Careers" color="white" url="https://www.linkedin.com/jobs/the-charles-jobs-worldwide?f_C=2684553&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0" />
								</div>
								<div className="photo-bg">
									<BackgroundImage url={fallback} mobileURL={fallback} videoURL="https://player.vimeo.com/external/454099297.sd.mp4?s=c6598728021141f5f80b4ad9a45aa08cacf581f8&profile_id=165" mobile="true" />
									<BrowserView video="https://player.vimeo.com/external/454099297.sd.mp4?s=c6598728021141f5f80b4ad9a45aa08cacf581f8&profile_id=165"/>
								</div>
							</div>
						</LazyLoad>
					</div>
				</div>
			</div>
		);
	}
}
