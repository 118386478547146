import React, { Component } from "react";


import {scroll_init, scroll_handler, scroll_kill, scroll_update, setPosition} from "../utilities/scroll_handler.js";
import InView from "react-intersection-observer";
// import utility_data from "../utilities/browser_information.js";


export default class LazyLoad extends Component {
	constructor(props){
		super(props);
		this.state = {
			position: null
		};
		this.in_view = true
		this.scroll_init = scroll_init.bind(this);
		this.scroll_handler = scroll_handler.bind(this);
		this.scroll_kill = scroll_kill.bind(this);
		this.scroll_update = scroll_update.bind(this);
		this.setPosition = setPosition.bind(this);
	}
	componentDidMount(){
		this.scroll_init();
	}
	componentWillUnmount(){
		this.scroll_kill();
	}
	render() {
		let in_view = this.in_view ? " in-view" : "";
		const outside_classes = this.props.className ? " " + this.props.className : "";
		return(
			<InView triggerOnce={true} as="div" onChange={inView => inView && this.group ? this.group.classList.add('in-view') : this.group.classList.remove('in-view')}>
				<div className={"lazy-load-group" + in_view + outside_classes} ref={ group => this.group = group }>
					{this.props.children}
				</div>
			</InView>
		);
	}
}