function makeObjectFit() {
    if ('objectFit' in document.documentElement.style === false || /Edge/.test(navigator.userAgent)) {
        Array.prototype.forEach.call(document.querySelectorAll('img[data-objectfit], video[data-objectfit]'), function (image) {
            if (image.getAttribute('data-objectfit') === 'contain') {
                if (image.offsetWidth > image.offsetHeight) {
                    image.style.width = '100%'
                    image.style.height = 'auto'
                }
                else if (image.offsetWidth < image.offsetHeight) {
                    image.style.width = 'auto'
                    image.style.height = '100%'
                }
            }
            if (image.getAttribute('data-objectfit') === 'cover') {
                if (image.offsetWidth < image.offsetHeight) {
                    if (window.innerWidth / window.innerHeight > image.offsetWidth / image.offsetHeight) {
                        image.style.height = 'auto'
                        image.style.width = '120%' 
                    }
                    else {
                        image.style.height = '120%'
                        image.style.width = 'auto' 
                    }
                }
                else {
                    if (window.innerWidth / window.innerHeight > image.offsetWidth / image.offsetHeight) {
                        image.style.height = 'auto'
                        image.style.width = '120%' 
                    }
                    else {
                        image.style.height = '120%'
                        image.style.width = 'auto' 
                    }
                }
            }
        });
        setTimeout(makeObjectFit, 2000)
    }
}

export { makeObjectFit }