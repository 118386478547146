// import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import utility_data from "./browser_information.js";

export const scroll_init = function(){
	this.setPosition()
	setTimeout(()=>{
		document.addEventListener('scroll', this.scroll_handler);
		window.addEventListener('viewport-update', this.scroll_update);
	}, 200)
	setTimeout(()=>{
		this.scroll_handler()
	}, 400)
};

export const setPosition = function(){
	const node = findDOMNode(this);
	const old_position = this.state.position;
	const new_postition = node.getBoundingClientRect().top + (utility_data.browser_height/4);
	if(new_postition !== old_position){
		setTimeout(()=>{
			this.setState({
				position: new_postition
			});
		}, 200)
	}
}

export const scroll_update = function(){
	this.setPosition()
	setTimeout(()=>{
		if(utility_data.scrollBot > this.state.position + 1 && !this.state.in_view){
			this.setState({
				in_view: true
			});
		}
		if(utility_data.scrollBot < this.state.position && this.state.in_view){
			this.setState({
				in_view: false
			});
		}
	}, 400)
};

export const scroll_handler = function(){
	if(utility_data.scrollBot > this.state.position + 1 && !this.state.in_view){
		this.setState({
			in_view: true
		});
	}
};

export const scroll_kill = function(){
	document.removeEventListener('scroll', this.scroll_handler);
	window.removeEventListener('viewport-update', this.scroll_update);
};
