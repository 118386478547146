import React, { Component } from "react";
import { Link } from 'gatsby';
import LazyLoad from "../repeating_elements/Lazy_Load.js";
import BackgroundImage from "../repeating_elements/Background-Image.js";

export default class Listing extends Component {
	render(){
		return(
			<LazyLoad>
				<a id={this.props.title.toLowerCase().split(" ").join("_")} link={this.props.title.toLowerCase().split(" ").join("_")} title={this.props.title}> </a>
				<div className="listing-item contact-listing row">
					<div className="grid-m-6 grid-t-10 grid-d-10 photo-contain">
						<div className="photo">
							<BackgroundImage url={this.props.image} loading="lazy"/>
						</div>
					</div>
					<div className="grid-m-6 grid-t-6 grid-d-6 content">
						<h4 className="styling-header-2">{this.props.title}</h4>
						<p dangerouslySetInnerHTML={{__html: this.props.description}}></p>
						<a href={this.props.url} title={this.props.title} target="_blank" rel="noopener noreferrer">View in maps</a>
						<div className="office-contact"><br />
							<Link to="/contact/new-business" title="Get in touch for a new business">New business</Link>
						</div>
					</div>
					<div className="grid-m-hide grid-t-16 grid-d-16 page-break"></div>
				</div>
			</LazyLoad>
		);
	}
}
