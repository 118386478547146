import React, { Component } from 'react';
import { Link } from 'gatsby';
import { ParallaxProvider } from 'react-scroll-parallax';
import PageTransition from 'gatsby-plugin-page-transitions';
import Navigation from "../components/Header/Navigation.js";
import GetInTouchFooter from "../components/Global/Get_In_Touch_Footer.js";
import Footer from "../components/Footer/Footer.js";
import BasicHero from "../components/Global/Basic_Hero.js";
import Listing from "../components/Contact/Listing.js";
import WorkWithUs from "../components/Global/Work_With_Us_Button.js";
import GDPR from '../components/Global/GDPR';
// import Loader from '../components/Global/Loader';
import SEO from '../components/seo.js';


class Contact extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLoaded: this.props.pageContext.isLoaded,
			isLazyLoaded: this.props.pageContext.isLazyLoaded,
			isReady: this.props.pageContext.isReady,
			data: this.props.pageContext.data,
			cookieBanner: this.props.pageContext.cookieBanner,
			headerLinks: this.props.pageContext.header,
			footerLinks: this.props.pageContext.footer,
		}
	}
	componentDidMount() {

		setTimeout(()=>{
			let element = window.location.hash ? document.querySelector(window.location.hash) : false;
			let offset = document.body.clientWidth > 700 ? 230 : 80;

			if(element){
				window.scroll({
					behavior: 'smooth',
					left: 0,
					top: element.getBoundingClientRect().top - offset
				});
			}
		}, 3000)
	}
	render_listing(){
		if(this.state.data){
			return this.state.data[0].acf.pages.map( (item) =>{
				return <Listing key={item.title} title={item.title} description={item.description} image={item.image.sizes.large} url={item.map_link} />;
			});
		}
	}
	render() {
		let page_ready = this.state.isLoaded && this.state.isReady ? "page-ready page-loaded show-text" : "page-loading";
		let data = this.state.data;
		if(data){

			data = this.state.data[0].acf;
			return (
                <ParallaxProvider><PageTransition>
				<GDPR data={this.state.cookieBanner} />

				<SEO
					title={this.state.data[0].yoast_meta.yoast_wpseo_title}
					description={this.state.data[0].yoast_meta.yoast_wpseo_metadesc}
				/>
				<div className="site-contain">
					<Navigation links={this.state.headerLinks} />
					  <div className="page-main">
                        <WorkWithUs />
                        <div className={"page contact-page " + page_ready}>
                            <BasicHero>
                                <h3 className="styling-eyebrow">{data.title}</h3>
                                <h2 className="styling-header-1">
                                    Do you want to <Link to="/contact/new-business" title="Start a project">start a project</Link>, <a href="https://www.linkedin.com/jobs/the-charles-jobs-worldwide?f_C=2684553&trk=top-card_top-card-primary-button-top-card-primary-cta&position=1&pageNum=0" title="Join the team" target="_blank" rel="noopener noreferrer">join the team</a> or just say hi?<br/>We'd love to hear from you.
                                </h2>
                            </BasicHero>
                            <div className="grid contact-listing-grid">
                                <div className="grid-m-6 grid-t-16 grid-d-16 page-break contact-page-break"></div>
                                <h3 className="styling-eyebrow grid-m-6 grid-t-16 grid-d-16">Our offices</h3>
                                {this.render_listing()}
                            </div>
                            <GetInTouchFooter />
                            <Footer links={this.state.footerLinks} />
                        </div>
                    </div>
				</div>
			</PageTransition></ParallaxProvider>
			);
		} else{
			return (
				<div></div>
			);
		}
	}
}

export default Contact;
